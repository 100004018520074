import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import CodeBlock from "../components/misc/code-block";
import Layout from "../components/layout/layout";
import Seo from "../components/misc/Seo/seo";
import { useAddThis } from "../hooks/useAddThis";

const components = {
    pre: (props) => <pre {...props} />,
    code: CodeBlock,
};

const Page = ({ data, pageContext }) => {
    const page = data.mdx;
    const menus = pageContext.menus?.items;
    const heroImage = page?.frontmatter?.pageHeroImage?.publicURL ?? null;
    const pageClasses = page.slug && page.slug.length > 0 ? page.slug.replace("/", " ") : "index";
    const extraBodyClasses = page?.frontmatter?.body_class ?? "";
    const headerType = ["none", "security"].includes(page?.frontmatter?.headerType)
        ? page?.frontmatter?.headerType
        : "page";
    // robots needs to be an array
    const metaRobots = page?.frontmatter?.robots ?? ["index", "follow"];

    useAddThis();

    return (
        <MDXProvider components={components}>
            <Layout
                menus={menus}
                headerType={headerType}
                pageHeading={page.frontmatter.pageHeading}
                pageSubHeading={page.frontmatter.pageSubHeading}
                pageHeroImage={heroImage}
                dual_cta={page.frontmatter.dual_cta}
                no_contact_cta={page.frontmatter.no_contact_cta}
                ctaClasses={`st-dual-ctas`}
            >
                <Seo
                    type={"Page"}
                    title={page.frontmatter.pageHeading || page.frontmatter.title}
                    description={page.frontmatter.description}
                    slug={page.fields.slug}
                    bodyClass={`${pageContext.type} ${pageClasses} ${page.slug} ${extraBodyClasses}`}
                    robots={metaRobots}
                />

                <MDXRenderer>{page.body}</MDXRenderer>
            </Layout>
        </MDXProvider>
    );
};

export default Page;

export const query = graphql`
    query($slug: String!) {
        mdx(fields: { slug: { eq: $slug } }) {
            id
            body
            slug
            frontmatter {
                changefreq
                description
                dual_cta
                no_contact_cta
                layout
                sitemap_priority
                title
                pageHeading
                pageSubHeading
                pageHeroImage {
                    publicURL
                }
                body_class
                headerType
                robots
            }
            fields {
                slug
            }
        }
    }
`;
